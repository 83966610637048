<!--
 * @Description: 用户提现
 * @Author: 琢磨先生
 * @Date: 2022-06-17 15:19:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-19 02:27:32
-->
<template>
  <el-card class="box">
    <el-tabs v-model="query.status" @tab-change="tabChange">
      <el-tab-pane label="全部" name></el-tab-pane>
      <el-tab-pane label="错误" name="40"></el-tab-pane>
    </el-tabs>
    <el-form ref="query" class="query" :model="query" :inline="true">
      <el-form-item label="批次名称">
        <el-input v-model="query.q" placeholder clearable></el-input>
      </el-form-item>
      <!-- <el-form-item label="状态">
        <el-select
          v-model="query.status"
          clearable
          placeholder=""
          style="width: 120px"
        >
          <el-option label="正常" :value="0"></el-option>
          <el-option label="冻结" :value="1"></el-option>
          <el-option label="注销" :value="2"></el-option>
        </el-select>
      </el-form-item>-->

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card class="box" v-loading="loading">
    <el-table :data="tableData.data" border>
      <el-table-column label="编号" prop="id" width="100"></el-table-column>
      <!-- <el-table-column label="头像" width="100">
        <template #default="scope">
          <el-avatar :size="50" :src="scope.row.avatar_url" />
        </template>
      </el-table-column>-->
      <el-table-column label="批次名称" prop="tenpay.batch_name"></el-table-column>
      <el-table-column label="金额" prop="decimal_amount" width="120">
        <template #default="scope">￥{{ scope.row.decimal_amount}}</template>
      </el-table-column>
      <el-table-column label="实际到账" prop="decimal_actual_amount" width="120">
        <template #default="scope">￥{{ scope.row.decimal_amount}}</template>
      </el-table-column>
      <el-table-column label="手续费" prop="bank_charge" width="120">
        <template #default="scope">￥{{ scope.row.decimal_bank_charge}}</template>
      </el-table-column>
      <!-- <el-table-column label="等级" width="200">
        <template #default="scope">
          {{ scope.row.grade ? scope.row.grade.name : scope.row.level_text }}
        </template>
      </el-table-column>-->
      <el-table-column label="状态" width="120" prop="status_text">
        <template #default="scope">
          <!-- <el-tag
          :type="scope.row.status != 0 ? 'danger' : 'success'"
          size="small"
          >{{ scope.row.is_stop ? scope.row.status_text : "正常" }}</el-tag>-->

          <el-tag type="danger" v-if="scope.row.status==40">{{scope.row.status_text}}</el-tag>
          <span v-else>{{scope.row.status_text}}</span>
        </template>
      </el-table-column>
      <el-table-column label="转账结果" width="240">
        <template #default="scope">
          <div>{{scope.row.tenpay.res_error_code}}</div>
          <div>{{scope.row.tenpay.res_message}}</div>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-popconfirm
            v-if="scope.row.status==40"
            title="您确定要重试提交转账？"
            @confirm="onTransfer(scope.row.tenpay)"
          >
            <template #reference>
              <el-button type="primary" link size="small" :loading="scope.row.tenpay.loading">重试转账</el-button>
            </template>
          </el-popconfirm>
          <!-- <el-popconfirm
            v-if="!scope.row.is_super"
            title="您确定要删除？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button size="small" type="text" class="btn-del"
                >删除</el-button
              >
            </template>
          </el-popconfirm>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
        status: "",
      },
      tableData: {
        counts: 0,
      },
      current: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 查询搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    tabChange() {
      this.onSearch();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("admin/v1/withdraw", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 重试提交转账
     */
    onTransfer(item) {
      item.loading = true;
      this.$http
        .get("admin/v1/withdraw/retry?tenpayId=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.loadData();
          }
        })
        .finally(() => {
          item.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.query .el-form-item {
  margin-bottom: 0;
}
</style>